import React from 'react'
import Layout from '../../components/layout/Layout';
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import '../careers.css'
import { Link } from 'gatsby';
import { Box, Stack, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function Example() {
  let disqusConfig = {
    url: 'https://www.c4scale.com/blogs/genai-adoption-mitigating-risks',
    identifier: '1',
    title: 'Top Privacy Concerns in adopting GenAI & practical tips to mitigate them',
  }
  return (
    <>
      <head>
        <title>C4Scale - Blog page</title>
        <meta name="description" content="Adopt Generative AI securely. Mitigate privacy concerns with Generative AI with practical tips for CSO, DPPO, Enterprise Leaders, CXOs"></meta>
      </head>
      <Layout>
        <div>
          <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16 relative">
            <div className="bg-cover h-64 text-center overflow-hidden" style={{ height: '450px', backgroundImage: 'url("https://res.cloudinary.com/durti17dk/image/upload/v1716885160/blog-assets/Privacy-Concerns-_eewstl_a4dbk0.webp")' }} title="">
            </div>
            <div className="max-w-4xl mx-auto">
              <div className="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                <div className="my-10">
                  <a href="/#" className="text-xs text-indigo-600 uppercase font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                    GenAI , DataPrivacy
                  </a>

                  <h1 className="text-gray-900 font-bold text-3xl mb-3 mt-1">Top Privacy Concerns in adopting GenAI & practical tips to mitigate them</h1>
                  <h1 className="text-gray-700 font-semibold text-lg mb-3">ChatGPT is powerful but your data is sensitive & critical.</h1>

                  <p className="text-gray-700 text-xs mt-2">Written By: <a href="https://www.linkedin.com/in/chakravarthyvp/" className="text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out" target='blank'>
                    Chakravarthy Varaga
                  </a></p>
                  {/* <h3 className="text-2xl font-bold my-5">Unreliable Data Leads to Hindered Decision Making</h3> */}
                  <p className="text-21 leading-8 my-5">
                    “Despite the enthusiasm, enterprises are slow to adopt commercial LLMs — like GPT provided by OpenAI — as they share several concerns. In fact, less than a 1/4th of surveyed companies are comfortable using commercial LLMs in production.<i><span style={{ fontWeight: "500" }}>At a high level, data privacy concerns top the list. In our discussions, nearly 40% of companies voiced concerns about sharing proprietary or sensitive data with LLM vendors</span></i>” </p>
                  <img className="w-full h-full object-cover" src="https://res.cloudinary.com/durti17dk/image/upload/v1716885159/blog-assets/95a0f3c9-7fac-4858-880b-e824148fd73b_2628x544_hqow3z_dhzs3f.webp" alt="Generated from ChatGPT" />
                

                  <p className="text-21 leading-8 my-5">
                    Survey conducted by Predibase with over 150 CXOs/Leads in adopting GenAI in their organisations.
                    <br /><br />
                    From my discussion with multiple Heads/DataScientists across cos, one approach that everyone talked about is to fine-tune open source models such as Llama2 and host it in the private infrastructure. This may help alleviate from sharing sensitive data with public vendors like OpenAI/ChatGPT/Mistral/Anthropic.
                  </p>
                  <p className="text-21 leading-8 my-5 italic">
                    However, In an adverse event of gaining access to your model weights, a malicious actor could extract your organisation’s sensitive data from the model.
                  </p>
                  <p className="text-21 leading-8 my-5 text-center font-semibold italic">
                    <hr className='mb-4' />
                    Your Generative AI model is an asset. Treat it like one.
                    <hr className='mt-4' />
                  </p>
                  <p className="text-21 leading-8 my-5 font-semibold">
                    Practical Tips & Necessary GuardRails to Mitigate Privacy Concerns
                    <br /><br />
                    Zero-Data-Loss and Data Anonymisation
                  </p>
                  <h3 className="text-lg font-medium my-5">Personal, financial, health related or sensitive data cannot be fed into</h3>
                  <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                    <li>Prompts</li>
                    <li>ChatGPT, OpenAI/Public LLM Endpoints</li>
                    <li>Internal LLM Models</li>
                    <li>APIs & 3rd Party Systems</li>
                  </ul>
                  <p className="text-21 leading-8 my-5">
                    <span className="italic font-medium">Ensure it is anonymised to protect individual identities</span> such as name, age, email, phone number, personal identities, health information, credit card, expiry dates, cvv etc. and <span className="italic font-medium">organisation sensitive information such as Revenue numbers, business strategies, financials, brand values etc.</span> </p>
                  <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                    <li>Techniques such as data masking can prevent the disclosure of personal information.</li>
                  </ul>
                  <p className="text-21 leading-8 my-5 font-semibold">
                    Synthetic Data
                  </p>
                  <p className="text-21 leading-8 my-5">
                    It is possible to extract training data including sensitive confidential information from pre-trained model using simple attack vectors.</p>
                  <p className="text-21 leading-8 my-5">
                    Use synthetic data(pseudo anonymisation) as a replacer to real sensitive data to fine tune a LLM. This way your data even if extracted does not leak confidential information.</p>
                  <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                    <li>Ensure that the synthetic replaceable data is contextually relevant, personalised & biased with your business context, (eg. you sell products in Singapore, have the products, brands relevant to the region)</li>
                  </ul>
                  <p className="text-21 leading-8 my-5 font-semibold">
                    Data Moderation with confidential terms detection
                  </p>
                  <p className="text-21 leading-8 my-5">
                    Every business has a set of key/valuable terms that are sensitive & proprietary to the organisation. It could be your marketing strategy, revenue numbers, high-premium customer segments.</p>
                  <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                    <li>Your chat prompts and the model end-point needs a moderation layer that should detect these sensitive terms and blocks them through pre-defined policies.</li>
                  </ul>
                  <p className="text-21 leading-8 my-5">
                    This privacy layer provides a single pass sensitive/personal data identification, redaction, replacement with fake data that is contextually relevant and coherent. The responses from the LLMs have to be moderated to detect malicious code.</p>
                  <p className="text-21 leading-8 my-5 font-semibold">
                    Data & Model Governance
                  </p>
                  <p className="text-21 leading-8 my-5">
                    Implement strict access controls through a governance framework to limit who can input data into the GenAI system and who can access the outputs. Ensuring that only authorised personnel have access can significantly reduce the risk of data breaches.</p>
                  <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                    <li>The moderation layer can be intelligent to incorporate your organisation’s authorisations & privileges to access resources.</li>
                    <li>Treat your model & the necessary data that goes in and out of the model as another set of resources. </li>
                    <li>For eg. this could be your HR policies which are available for access to only a certain grade and above. Compensation for a grade like ‘G6‘ cannot be made available to any grades below ‘G5‘</li>
                  </ul>
                  <p className="text-21 leading-8 my-5 font-semibold">
                    Privacy-by-Design
                  </p>
                  <p className="text-21 leading-8 my-5">
                    Adopt a privacy-by-design approach at every stage of the development process, from initial design, model design, pre-training/fine-tuning process to deployment, inference & GA access, ensuring that privacy protection is baked into the technology.</p>
                  <p className="text-21 leading-8 my-5 font-semibold">
                    Centralised Inventory, Catalog of GenAI Implementations
                  </p>
                  <p className="text-21 leading-8 my-5">
                    Have a repo of all GenAI implementations that tracks</p>
                  <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                    <li>the models and their checkpoints</li>
                    <li>datasets used</li>
                    <li>productionalised versions and their purposes (explainability)</li>
                  </ul>
                  <p className="text-21 leading-8 my-5">
                    This repository and catalog listing improves transparency & explainability of your models & their usage across the organisation.</p>
                  <p className="text-21 leading-8 my-5 font-semibold">
                    Regular Audits and Compliance Checks
                  </p>
                  <p className="text-21 leading-8 my-5">
                    Conduct regular audits of your GenAI systems to ensure they comply with data protection laws such as GDPR, CCPA, DPDPA or any other relevant legislation.</p>
                  <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                    <li>This includes reviewing data handling practices and the model's outputs for any potential privacy violations.</li>

                  </ul>
                  <p className="text-21 leading-8 my-5 font-semibold">
                    Transparency and Consent
                  </p>
                  <p className="text-21 leading-8 my-5">
                    Be transparent & explainable with your stakeholders about the use of GenAI technologies and the data it processes.  </p>
                  <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                    <li>Obtain explicit consent from individuals whose data may be used, clearly explaining how their data will be handled and for what purposes.</li>
                    <li>Keep your “Data Principles” informed of the data used. These are your customers/users of your services.</li>
                    <li>Keep your Compliance & Risk Officer in loop for all sources of information including the data that you train on the model</li>

                  </ul>
                  <p className="text-21 leading-8 my-5 font-semibold">
                    We are building a customisable privacy layer with necessary guardrails, policy based configurable detection & moderation capabilities needed to secure Business adoption of GenAI.
                  </p>
                 
                  <div className="mt-6 mb-2">
                    <Link
                      to="https://calendly.com/chakravarthy-varaga/introductory-call" target='blank'
                      className="w-max flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Talk to our product
                    </Link>
                  </div>
                  <span className='flex gap-1 flex-wrap mt-4'>
                    <a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                      #GenerativeAIPrivacy
                    </a><a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                      #DataLossPrevention
                    </a><a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                      #DataPrivacy
                    </a>
                    <a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                      #GenAIAdoption
                    </a>
                    <a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                      #PrivacyMitigationStrategies
                    </a>
                    <a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                      #GenAI
                    </a>
                    <a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                      #AIComplianceStrategies
                    </a>
                  </span>

                </div>
                <div className="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                <a href="https://www.designrush.com/agency/profile/c4scale" target="_blank" rel="noopener noreferrer" 
                    style={{ display: 'block', width: '100px' }}>
                    <div className="bg-cover text-center overflow-hidden" style={{ 
                      height: '100px', 
                      width: '100px', 
                      backgroundImage: 'url("https://res.cloudinary.com/durti17dk/image/upload/v1716964855/blog-assets/Verfifed_Agency_Hexagonal_v1_bv3nvo_itwwpv.webp")'
                    }} title=""></div>
                </a>
                <p className="text-21 leading-8 my-5">We're on DesignRush</p>
              </div>
                {/* <div className="my-10"> */}

                {/* </div> */}

              </div>
            </div>
          </div>
        </div>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          sx={{ marginTop: "20px", padding: "0 20px", marginBottom: "20px" }}
        >
          <Link to="/blogs/gen_ai_adoption_playbook_framework" style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#1f2937' }}>
              <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "18px", fontWeight: "bold" }} />
              <Typography variant="h6" sx={{ fontSize: "18px" }}>Previous</Typography>
            </Box>
          </Link>
          <Link to="/blogs/the-data-quality-jigsaw" style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#1f2937' }}>
              <Typography variant="h6" sx={{ fontSize: "18px" }}>Next</Typography>
              <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "18px", fontWeight: "bold", transform: 'rotate(180deg)' }} />
            </Box>
          </Link>
        </Stack>
        <section style={{ padding: '0 4rem' }}>
          <CommentCount config={disqusConfig} />
          <Disqus config={disqusConfig} />
        </section>
      </Layout>
    </>
  )
}
